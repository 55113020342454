import React, { Component } from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { sortBy } from 'lodash'
import { graphql, Link, StaticQuery } from 'gatsby'

import './layout.css'

import Navbar from '../components/Navbar'
import { below } from '../utils/media-query'
import Footer from './Footer'
import { PermissionPopup } from './PermissionPopup'
import { countries } from '../utils/constants'
import {EXP_MODE,getVariant} from '../utils/commonUtil'

const AppWrapper = styled.div`
  min-height: 100vh;
  font-family: 'Liberation Sans', Helvetica, sans-serif;
  background:${props => (props.IsPageLayout ? '#FFFFFF' : '#f4f4f4')} ;
  ${props => (!props.offerPage ?
  `
    padding-top: 3.5rem;
    padding-top: 3rem;

  `:null)}
  a {
    color: #646464;
    text-decoration: none;
    transition: color 0.2s ease, border-bottom-color 0.2s ease;
    :hover {
      color: #2ebaae;
    }
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    color: #3c3b3b;
    letter-spacing: 0.25em;
    line-height: 1.65;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
  }
  h3{
    font-size: 0.9rem;
  }
`

const BodyContainer = styled.div`
  opacity: ${props => (props.showMenu ? '0.15' : '1')};
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
`

const ToggleMenu = styled.section`
  -webkit-transition: visibility ease 0.5s, opacity 0.5s ease,
    transform 0.5s ease;
  -moz-transition: visibility ease 0.5s, ease 0.5s linear, transform 0.5s ease;
  -ms-transition: visibility ease 0.5s, opacity 0.5s ease, transform0.5s ease;
  -o-transition: visibility ease 0.5s, opacity 0.5s ease, transform 0.5s ease;
  transition: visibility ease 0.5s, opacity 0.5s ease, transform 0.5s ease;
  background: #ffffff;
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  position: fixed;
  box-shadow: none;
  margin-bottom: 5px;
  height: 100%;
  max-width: 80%;
  width: 25em;
  overflow-y: auto;
  right: -25em;
  top: 0;
  visibility: ${props => (props.showMenu ? 'visible' : 'hidden')};
  opacity: ${props => (props.showMenu ? '1' : '0.9')};
  transform: ${props => (props.showMenu ? 'translateX(-25em)' : null)};
  z-index: 10002;
  section {
    padding: 3em;
  }
  ul {
    list-style-type: none;
    margin: 0 0 2em 0;
  }
  li {
    border: 0;
    border-top: dotted 1px rgba(160, 160, 160, 0.3);
    margin: 1.5em 0 0 0;
    padding: 1.5em 0 0 0;
    :first-child {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }
  li a {
    text-decoration: none;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  img {
    opacity: 0.4;
    margin-right: 15px;
    margin-bottom: 1rem;
  }
  .active {
    h3 {
      color: #2ebaae;
    }
    img {
      opacity: 1;
    }
  }
  h3 {
    transition: color 0.2s ease;
    font-size: 0.7em;
    color: #3c3b3b;
    font-family: 'Raleway', Helvetica, sans-serif;
    font-weight: 800;
    letter-spacing: 0.25em;
    line-height: 1.65;
    margin: 0 0 1em 0;
    text-transform: uppercase;
    display: block;
    :hover {
      color: #2ebaae;
    }
  }
`
const BodyWrapper = styled.div`
  padding: 3rem;
  padding-bottom: 0;
  color: #646464;
   footer {
     margin-top: 3rem;
     align-items: start;
   }
  ${below.med`
    padding: 0;
  `}
  ${({ props }) =>
  props.IsBlogPage
    ? ` padding: 6rem 3rem 3rem 3rem;
    margin: 0 auto;
    color: #646464;
    background: #FFF;
    display: -ms-grid;
    display: grid;
    grid-template-areas: 'content header' 'content related';
    -ms-grid-columns: 5fr 3rem 2fr;
    grid-template-columns: 5fr 2fr;
    -ms-grid-rows: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 3rem;`
    : null}
    ${below.large`
      display: block;
    `}
    ${({props}) => props.IsPageLayout?`padding-top: 3rem;`:null}
    ${({props}) => props.IsLincxPage?`padding: 0; margin: 0; display: block;`:null}
    h2 {
      grid-area: header;
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      font-weight: 800;
      letter-spacing: 0.4rem;
      ${below.large`
        display: inline-block;
        width: 100%;
        text-align: center;
      `}
    }

`

class TemplateWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu })
  }

  isCurrentDocument = article => {
    if (typeof window !== `undefined`) {
      return window
        .decodeURI(window.document.URL)
        .match(article.node.fields.slug)
        ? 'active'
        : ''
    }
  }

  componentDidUpdate(prevProps, prevState, context) {
    if (this.state.showMenu) {
      document
        .getElementById('contentWrapper')
        .addEventListener('click', this.clickOutsideHandler)
    } else {
      document
        .getElementById('contentWrapper')
        .removeEventListener('click', this.clickOutsideHandler)
    }
  }

  clickOutsideHandler = e => {
    this.setState({ showMenu: !this.state.showMenu })
    const target = e.target
    e.preventDefault()
    setTimeout(function() {
      target.click()
    }, 250)
  }

  clickOutsideHandle(e) {
    if (this.state.showMenu) {
      this.setState({ showMenu: !this.state.showMenu })
    }
  }

  render() {
    // eslint-disable-next-line
    const { children, data, IsPageLayout } = this.props
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
            allMarkdownRemark(
              sort: { order: DESC, fields: [frontmatter___date] }
              filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            ) {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    country
                    betaTest {
                      live
                      title
                      weight
                    }
                    alphaTest {
                      live
                      title
                      weight
                    }
                  }
                }
              }
            }
            allFile(filter: { relativeDirectory: { eq: "country-flags" } }) {
              edges {
                node {
                  childImageSharp {
                    resize(width: 30, height: 20, cropFocus: CENTER) {
                      src
                    }
                  }
                  name
                }
              }
            }
          }
        `}
        render={data => {
          const sortedCountries = sortBy(data.allFile.edges, ({ node }) =>
            Object.keys(countries).indexOf(node.name)
          )
          const { removeFooter, offerPage, adsense } = this.props
          const countriesImages = data.allFile.edges.reduce((acc, cur, i) => {
            acc[countries[cur.node.name]] = cur.node.childImageSharp.resize.src
            return acc
          }, {})
          return (
            <div id="contentWrapper">
              <Helmet>
                {/*Global Site Tag (gtag.js) - Google Analytics*/}
                <script
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=UA-52114306-3"
                />
                <script>
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments)};
                    gtag('js', new Date());

                    gtag('config', 'UA-52114306-3', { 'transport_type': 'beacon' });
                  `}
                </script>
                {/*Hotjar Tracking Code for path.money*/}
                <script>
                  {`(function(h,o,t,j,a,r){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:659924,hjsv:6};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                   `}
                </script>
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js"></script>
              </Helmet>
              <AppWrapper
                onClick={e => this.clickOutsideHandle(e)}
                offerPage={offerPage}
                IsPageLayout={IsPageLayout}
              >
                <Helmet>
                  <html lang="en" />
                  <title>{data.site.siteMetadata.title}</title>
                  <meta
                    name="description"
                    content={data.site.siteMetadata.description}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/img/favicon-32x32.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="/img/favicon-32x32.png"
                    sizes="32x32"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    href="/img/favicon-16x16.png"
                    sizes="16x16"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Raleway:400,800,900|Source+Sans+Pro:400,600"
                    rel="stylesheet"
                  />
                  <link
                    rel="mask-icon"
                    href="/img/favicon-32x32.png"
                    color="#ff4400"
                  />
                  <meta name="theme-color" content="#fff" />
                  <meta property="og:type" content="business.business" />
                  <meta
                    property="og:title"
                    content={data.site.siteMetadata.title}
                  />
                  <meta property="og:url" content="/" />
                  <meta property="og:image" content="/img/og-image.jpg" />
                </Helmet>
                {adsense ? (
                  <Helmet>
                    <script
                      async
                      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                    />
                    <script>
                      {`(adsbygoogle = window.adsbygoogle || []).push({
                     google_ad_client: "ca-pub-6867008335629681",
                     enable_page_level_ads: true
                   });`}
                    </script>
                  </Helmet>
                ) : (
                  ''
                )}
                <BodyContainer showMenu={this.state.showMenu}>
                  <Navbar
                    toggleMenu={this.toggleMenu.bind(this)}
                    sortedCountries={sortedCountries}
                    hideCountriesNav={Boolean(IsPageLayout)}
                  />
                  <BodyWrapper props={this.props}>{children}</BodyWrapper>
                </BodyContainer>
                <Footer removeFooter={removeFooter} offerPage={offerPage} IsPageLayout={IsPageLayout} />
              </AppWrapper>
              <ToggleMenu id="menu" data-testid="template-sidebar" showMenu={this.state.showMenu}>
                <section>
                  <ul className="links">
                    {data.allMarkdownRemark.edges.map(article => {
                     let {title, alphaTest, betaTest} =article.node.frontmatter
                      let variant = getVariant(alphaTest, betaTest)
                      let currentTitle = title;
                      switch(variant){
                        case EXP_MODE.ALPHA:
                          currentTitle = alphaTest.title || title;
                          break
                        case EXP_MODE.BETA:
                          currentTitle = betaTest.title || title;
                          break
                        default:
                          // do nothing
                      }

                      return (
                        <li key={article.node.fields.slug}>
                          <Link
                            to={article.node.fields.slug}
                            className={this.isCurrentDocument(article)}
                          >
                            <img
                              src={
                                countriesImages[
                                  article.node.frontmatter.country
                                ]
                              }
                              alt={article.node.frontmatter.country}
                            />
                            <h3>{currentTitle}</h3>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </section>
              </ToggleMenu>
              <PermissionPopup />
            </div>
          )
        }}
      />
    )
  }
}

export default TemplateWrapper
