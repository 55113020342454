import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { above, below } from './utils/media-query'

export const StyledImage = styled(Img)`
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.05);
  }
`

export const HomePage = styled.div`
  display: -ms-grid;
  display: grid;
  margin-top: 3rem;
  grid-template-areas:
    'title article-feed article-feed'
    'sidebar article-feed article-feed';
  -ms-grid-columns: 1.7fr 56px 4fr;
  grid-template-columns: 1.7fr 2fr 2fr;
  grid-template-rows: auto 1fr;
  -ms-grid-rows: auto 1fr;
  column-gap: 56px;

  ${below.large`
    grid-template-areas:
      'title'
      'article-feed';

    ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  `}
`

export const HomeTitle = styled.div`
  grid-area: title;
  margin-bottom: 48px;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
   h2, h3 {
    font-size: 32px;
    font-weight: 900;
  }

  h2 {
    padding: 0;
  }

  ${below.large`
    display: flex;
    justify-content: center;
  `}

  ${below.med`
    margin-top: 48px;
  `}

  ${below.small`
    h2 {
      font-size: 20px;
    }
  `}
`

export const StyledSideBar = styled.section`
  gird-area: sidebar;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
`

export const StyledArticlesSection = styled.div`
  padding-top: 48px;
  border-top: solid 1px rgba(160, 160, 160, 0.3);

  ${below.large`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
  `}
  ${below.med`
      width: 90%;
      margin: 0 auto;
  `}
  ${below.tiny`
    flex-direction: column;
  `}
`
export const StyledAboutSection = styled.div`
  ${below.large`
    width: 100%;

    footer {
      align-items: center;
    }
  `}
  ${below.med`
    width: 90%;
    margin: 0 auto;
  `}
`

export const AboutDataContainer = styled.div`
  padding-top: 48px;
  border-top: solid 1px rgba(160, 160, 160, 0.3);
`

export const MiniPost = styled.article`
  background: #fff;
  font-family: Raleway, Helvetica, sans-serif;
  margin-bottom: 32px;
  border: solid 1px rgba(160, 160, 160, 0.3);

  a {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    color: #3c3b3b;
  }

  header {
    padding: 20px;
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
    }
  }

  h3 {
    font-size: 11.2px;
    font-weight: 800;
    margin-bottom: 0;
  }

  time {
    font-size: 10px;
    line-height: 1.75;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
  ${above.large`
    :last-of-type {
      margin-bottom: 3rem;
    }
  `}

  ${below.large`
      margin: 2em 2em 0 0;
      width: calc(50% - 2em);
    `}
  ${below.tiny`
    {
      width: 100%
    }
   `}
`

export const ArticleFeed = styled.div`
  grid-area: article-feed;
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  -ms-grid-row-span: 2;
`

export const BlogBody = styled.section`
  a {
    opacity: 0.8;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2e5dba;
  }
  padding: 0rem 0rem 3rem;
  ${below.med`
    padding: 1rem 1.5rem 0px;
  `}
`

const contentFontFamily = `'Liberation Sans', Helvetica, sans-serif`
export const BlogContent = styled.div`
  h2 {
    text-align: left;
  }
  h1,
  h2,
  h3,
  h4  {
    font-family: Poppins;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #3c3b3b;
    text-transform: none;
    ${below.med`
      font-size: 18px;
    `}
  }
  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    margin-bottom: 24px;
    ${below.med`
      font-size: 16px;
    `}
  }
  a {
    text-decoration: underline;
  }
  ol {
    margin-left: 1rem;
  }
  li {
    margin: 0;
  }
  .disclaimer {
    font-size: 80%;
    font-weight: bold;
    font-family: serif;
  }
  .offer__button {
    text-decoration: none;
    color: white;
  }
  .offer__button:hover {
    color: white;
  }
  .multi-offer__list {
    width: 100%;
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  .multi-offer__btn {
    text-decoration: none;
    color: #f6f6f6;
    background-color: #2ebaae;
    transition: all 0.3s ease;
    cursor: pointer;
    font-weight: 800;
    letter-spacing: 0.0125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.125rem;
    border-radius: 0.5rem;
    text-shadow: 0 1px 1px #104f33;
    height: 4rem;
  }
  .multi-offer__btn:hover{
    color: #fff;
    background-color: rgba(46, 186, 174, 0.71);
    transition: all 0.3s ease;
  }
  .chevron {
    border-right: 0.125rem solid #fff;
    border-bottom: 0.125rem solid #fff;
    width: 0.625rem;
    height: 0.625rem;
    transform: rotate(-45deg);
  }
  .multi-offer li {
      width: inherit;
      margin: 0.7% 0.5%;
      display: flex;
      justify-content: center;
      flex-direction: column;
  }

  /** lincx Ad Style START */
  .offersTarget {
    .offers {
      padding-left: 0;

      .offers__header {
        font-family: ${contentFontFamily} !important;
        font-size: 1rem !important;
        font-weight: normal;
      }
      .offers__list {
        margin-left: 0 !important;

        .offer__link, .offer__button {
          font-family: ${contentFontFamily} !important;
          font-size: 1rem !important;
        }
        .offer__content {
          .offer__headline{
            font-family: ${contentFontFamily} !important;
            font-size: 1rem !important;
          }
        }
      }
    }
  }
  /** lincx Ad Style END */

  ${above.small`
    .multi-offer__list {
      justify-content: space-around;
    }
    .multi-offer li {
      flex-basis: calc(50% - 40px);
    }
    .multi-offer__btn {
      font-size: 1rem
    }
    .multi-offer {
      padding-left: 1rem;
    }
  `}
  ${above.large`
    .multi-offer__btn {
      font-size: 1.25rem
    }
  `}
  ${below.small`
    .multi-offer {
      padding-right: 1.5rem;
    }
    .multi-offer__btn {
      height: 3rem;
    }
  `}

`

export const BlogWrapper = styled.article`
  grid-area: content;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  background: #fff;
  margin: 0 0 3rem 0;
  ${above.xlarge`
    max-width: none;
  `}
  ${below.med`
    margin: 0 0 24px 0;
  `}
  ${BlogBody} {
    h1 {
      font-size: 1rem;
      font-weight: 800;
    }

    h2 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #3c3b3b;
      text-transform: none;
      ${below.med`
        font-size: 18px;
        line-height: 1.67;
        color: #3c3b3b;
        text-transform: none;
    `}
    }

    h4 {
      text-transform: none;
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #3c3b3b;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin: 0 0 24px 0;
      font-weight: 400;
      ${below.med`
        font-size: 16px;
      `}
      a {
        opacity: 0.8;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2e5dba;
      }
    }
    ${below.small`
      p {
        font-size: 16px;
      }
      h4 {
        text-transform: none;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 14px;
      }
    `}
  }
  ${below.small`
    h2 {
      padding: 0;
      margin-bottom: 7px;
    }
  `}
`

export const BlogHeader = styled.div`
  display: flex;
  ${below.med`
    flex-direction: column;
    padding: 3rem 1.5rem 0.5rem;
  `}
  ${below.small`
    padding: 1.75rem 1.5rem 0rem;
    time{
      display: none;
    }
  `}
`

export const BlogTitle = styled.div`
  padding: 3.75rem 2.5rem 3.25rem 3rem;
  flex-grow: 1;

  h1 {
    font-size: 1.75rem;
    margin: 0;
  }

  h1:hover {
    color: #2ebaae;
  }

  h2 {
    font-family: 'Liberation Sans', Helvetica, sans-serif;
    margin: 0;
    letter-spacing: 0.25rem;
    padding: 0;
    font-size: 1.5rem;
  }

  ${below.med`
    padding: 0;
    margin-bottom: 2rem;

    h1 {
      text-align: center;
    }
  `}
  ${below.small`
    margin-bottom: 0rem;
    h2 {
      padding: 0;
      font-size: 100%;
    }
  `}
`

export const ContactInfo = styled.div`
  font-family: 'Liberation Sans', Helvetica, sans-serif;
  font-size: 1.16rem;
  color: #646464;
  line-height: 2rem;
  strong {
    color: rgb(60, 59, 59);
  }

`

export const Meta = styled.div`
  padding: 3.75rem 3rem 1.75rem 3rem;
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  min-width: 17rem;
  text-align: right;
  width: 17rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  time {
    font-family: 'Raleway', Helvetica, sans-serif;
    font-size: 0.7rem;
    line-height: 22.75px;
    font-weight: 800;
    color: #3c3b3b;
    letter-spacing: 0.175rem;
    margin-top: 0.35rem;
    margin-bottom: 0.7rem;
  }

  img {
    border-radius: 100%;
    display: block;
    max-width: 2.4rem;
    max-height: 2.4rem;
  }

  div {
    display: flex;
    align-items: center;
  }

  ${below.med`
  padding: 0;
  border: 0;
  width: 100%;
  align-items: center;
  min-width: 0;
  flex-direction: row;
  margin-bottom: 2rem;

  time {
    margin: 0;
  }

  a {
    margin-left: 1.2rem;
    padding-left: 1.2rem;
    border-left: solid 1px rgba(160, 160, 160, 0.3);
  }
`}

  ${below.small`
  margin-bottom: 1rem;
  div {
    display: none;
  }
`}
`

export const MetaAuthor = styled.a`
  display: flex;
  span {
    margin-left: 1rem;
    letter-spacing: 0.25em;
    font-size: 0.6rem;
    line-height: 1.75;
    font-family: 'Raleway', Helvetica, sans-serif;
    border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
    color: rgb(100, 100, 100);
    align-self: center;

    &:hover {
      color: #2ebaae;
    }
  }
`

export const Banner = styled.div`
  margin-bottom: 24px;

  a {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    color: #3c3b3b;
  }
  ${below.small`
    margin: 0rem -1.5rem 24px;
  `}
`

export const NavWrapper = styled.nav`
  display: flex;
  background: #ffffff;
  height: 54px;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0px;
  left: 0px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px 0 rgba(112, 112, 112, 0.11);
  padding: 0 3rem;
  ${below.med`
    padding: 0;
  `}
  a {
    font-weight: 800;
    font-size: 0.8125rem;
    text-transform: uppercase;
    letter-spacing: 4.5px;
    color: #3c3b3b;
    font-family: Raleway, sans-serif;
    margin: 0 auto;
  }
`

export const NavTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  flex: 1;
  justify-self: center;
`

export const NavLinks = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-left: 1px solid rgba(160, 160, 160, 0.3);

  ${below.med`
    display: none;
  `}

  ul {
    display: flex;
    list-style: none;
  }

  img,
  ul,
  li {
    margin: 0;
  }

  li {
    padding: 0 1.4rem;
    border-left: 1px solid rgba(160, 160, 160, 0.3);
    display: flex;
    align-items: center;
  }

  li:first-of-type {
    border-left: 0;
    margin: 0;
    padding-left: 1.5rem;
    a {
      color: #646464;
    }
    .active {
      color: #2ebaae;
      font-weight: 800;
    }
  }

  img {
    opacity: 0.3;
  }

  a {
    font-weight: 400;
  }

  .active-image {
    img {
      opacity: 1;
    }
  }
  ${below.large`
    li {
      padding: 0 0.6rem;
    }
    ul {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    `}
`

export const LinkButton = styled(Link)`
  font-family: Raleway, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 800;
  color: #3c3b3b;
  letter-spacing: 0.2rem;
  line-height: 3.36875rem;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 40px;
  border: 0.5px solid #e2e2e2;
  display: inline-block;
  &:hover {
    border-color: #2ebaae;
  }
`
//Contact Form styles
export const ContactForm = styled.form`
  grid-area: contact;
  width: 100%;
  padding: 0;
  margin: 0px auto 2rem;
  color: #222;
  font-family: verdana, sans-serif;
  line-height: 1.3rem;
  border-radius: 0;
  outline: none;
  box-sizing: border-box;
  outline: none;
  .control {
    position: relative;
  }
  ${below.med`
    width: 90%;
  `}
   h3 {
     color: #3c3b3b;
     font-weight: 600;
     line-height: 1.65;
     margin: 0 0 2rem 0;
     text-transform: uppercase;
     font-size: 1.1rem;
   }
`

export const SubmitButton = styled.button`
  background: #444;
  color: #fff;
  padding: 1rem 2rem;
  width: auto;
  border-radius: 6.25rem;
  cursor: ${props => (props.disabled ? `not-allowed` : `pointer`)};
  border: none;
  margin: 0;
  white-space: normal;
  outline: none;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.25rem;
  height: 4.8125rem;
  &:hover {
    box-shadow: ${props =>
      props.disabled ? 'none' : `inset 0 0 0 1px #2ebaae`};
  }
`

export const UnSubscribeButton = styled.button`
  background: transparent;
  color: #3c3b3b;
  padding: 1rem 1rem;
  width: auto;
  cursor: pointer;
  border: 1px solid #3c3b3b;
  margin: 0;
  white-space: normal;
  outline: none;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.25rem;
  height: 3rem;
  margin-bottom: 1rem;
  &:hover {
    background: #3c3b3b;
    color: #FFF;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  color: #666;
  background: ${props => (props.error ? `#fff0f4` : `#fff`)};
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.75rem 1.25rem;
  margin: 0.9375rem 0 1.875rem;
  transition: all 0.2s;
  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px #2ebaae;
    background: #fff;
  }
  ${props => (props.error ? `box-shadow: 0 0 5px #CC0000;` : null)}
`

export const ErrorMessage = styled.p`
  background: #c51244;
  color: white;
  padding: 0.625rem;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #aaaaaa;
  position: absolute;
  right: 0;
  bottom: -3rem;
  min-width: 11rem;
  text-align: center;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #c51244;
    position: absolute;
    top: -10px;
    right: 10px;
  }
`
export const FormContainer = css`
  padding-top: 3rem;
  display: grid;
  grid-template-areas:
    'title contact'
    'sidebar contact';
  -ms-grid-columns: 0.8fr 2fr;
  grid-template-columns: 0.8fr 2fr;
  grid-column-gap: 4rem;
  -ms-grid-rows: auto 1fr;
  grid-template-rows: auto 1fr;
  footer {
    margin-top: 3rem;
    font-size: 0.5rem;
    align-items: start;
  }
  .control {
    position:relative;
  }
  ${below.large`
    display: flex;
    flex-direction: column;
    .content {
      width: 100%;
    }
    section {
      order: 3;
    }
  `}
  ${below.med`
    .content {
      width: 90%;
      margin: 0 auto;
    }
  `}
`
export const AboutText = css`
  padding-bottom: 2.5rem;
  border-bottom: solid 1px rgba(160,160,160,0.3);
  text-align: justify;
  h2 {
	  font-size: 0.8rem;
    color: #3c3b3b;
    font-family: "Raleway", Helvetica, sans-serif;
    font-weight: 800;
    letter-spacing: 0.25rem;
    line-height: 1.65;
    margin: 0 0 1.5rem 0;
    text-transform: uppercase;
  }
  p {
    line-height: 1.75;
    font-size: 0.9rem;
  }
`

export const StyledTextArea = StyledInput.withComponent('textarea')

export const globalForModal = css`
  nav[role='navigation'] {
    display: none;
  }
`
export const creditReportGlobal = css`
  ${globalForModal}
  #contentWrapper {
    div {
      background: white;
    }
  }
`
export const successStyles = css`
  h1 {
    color: #00e676;
    opacity: 0;
    -webkit-animation: fadeIn 1.5s 0.2s 1 forwards;
    animation: fadeIn 1.5s 0.2s 1 forwards;
    margin: 0 0 1rem;
    font-size: 2.3rem;
    line-height: 3rem;
  }
  span {
    font-size: 3.4375rem;
    line-height: 6.5rem;
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 6.875rem;
    border: 2px solid #00e676;
    display: inline-block;
    position: relative;
    &:before {
      border: 0 solid #00e676;
      border-width: 0 0 4px 4px;
      content: '';
      display: block;
      height: 2rem;
      left: 1.6rem;
      position: absolute;
      top: 1.8rem;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      width: 3.5rem;
    }
    &:after {
      background: #fff;
      content: '';
      display: block;
      height: 3.4375rem;
      right: 1.3rem;
      position: absolute;
      top: 2rem;
      width: 4.3rem;
      animation: checkMe 1s 1.1s 1 forwards;
    }
  }
`
export const errorStyles = css`
  h1 {
    color: #37474f;
    opacity: 0;
    -webkit-animation: fadeIn 1.5s 0.2s 1 forwards;
    animation: fadeIn 1.5s 0.2s 1 forwards;
    margin: 0 0 1rem;
    font-size: 2.3rem;
    line-height: 3rem;
  }
  span {
    font-size: 3.4375rem;
    line-height: 6.5rem;
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 6.875rem;
    border: 2px solid #37474f;
    display: inline-block;
    position: relative;
    text-align: center;
    &:after {
      background: #fff;
      content: '';
      display: block;
      height: 3.4375rem;
      right: 1.3rem;
      position: absolute;
      bottom: 2rem;
      width: 4.3rem;
      animation: alertMe 1.5s 1.1s 1 forwards;
    }
  }
  .Oops {
    font-size: 0.9rem;
    margin: 0;
    opacity: 0;
    animation: fadeIn 1.5s 0.2s 1 forwards;
  }
  ul {
    margin: 3.5rem 0;
    list-style: none;
    padding: 0;
    opacity: 0;
    animation: fadeIn 1.5s 0.5s 1 forwards;
    li {
      font-size: 1.05rem;
      margin: 0;
      padding: 0.5rem 0;
      border-bottom: 1px solid #f5f7f8;
      color: #f44336;
      &:first-child {
        border-top: 1px solid #f5f7f8;
      }
    }
  }
  .buttonBack {
    opacity: 0;
    animation: fadeIn 1.5s 0.8s 1 forwards;
    button {
      cursor: pointer;
      outline: none;
      color: #546e7a;
      background: none;
      border: 2px solid #546e7a;
      border-radius: 1.75rem;
      display: inline-block;
      height: 3.5rem;
      min-width: 12.75rem;
      line-height: 3.5rem;
      padding: 0 2.5rem;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.85rem;
    }
  }
`
export const modalStyles = isError => css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes checkMe {
    from {
      width: 4.3rem;
    }
    to {
      width: 0;
    }
  }
  @keyframes alertMe {
    from {
      height: 3.4375rem;
    }
    to {
      height: 0;
    }
  }
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 7% auto;
  padding: 7%;
  width: 60%;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font: 1rem 'Montserrat', Helvetica, Arial, sans-serif;
  color: #455a64;
  ${isError ? errorStyles : successStyles}
`
export const OfferContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-family: sans-serif;
    font-size: 2.1rem;
    font-weight: 900;
    color: #4d4c4d;
    letter-spacing: 0.2rem;
    text-transform: none;
  }
`
export const offerButton = css`
  .offerButton[role="adverse"]  {
      text-decoration: none;
      background-color: rgb(46, 186, 174);
      margin: 0 auto 2rem;
      box-shadow: rgba(160, 160, 160, 0.3) 0px 0px 0px 1px inset;
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: block;
      font-family: Raleway, Helvetica, sans-serif;
      font-size: 0.7rem;
      font-weight: 800;
      height: 3.3125rem;
      letter-spacing: 0.175rem;
      line-height: 3.3125rem;
      padding: 0 2.0625rem;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s ease;
      width: fit-content;
      -webkit-appearance: none;
      :hover {
        color: white;
        opacity: 0.7;
        transition: all 0.3s ease;
      }
      ${below.tiny`
        font-size: 0.6rem;
        width: inherit
      `}
  }
`
export const PopupContainer = styled.div`
  width: 400px;
  background: #fff;
  box-shadow: 10px 10px 40px 5px rgba(222,222,222,0.75);
  padding: 1rem;
  position: fixed;
  top: 20px;
  left: calc(50% - 200px);
  z-index:10;
  display: none;

  .permission__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .permission__brand-img {
      width: 100px;
      display: flex;
      justify-content: center;
    }
    .permission__details {
      .permission__main-contain {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .permission__info-content {
        font-size: 0.75rem;
      }
    }
  }
  .permission__action {
     display: flex;
     width: 100%;
     justify-content: center;
     .permission__action-btn {
      margin: 10px;
      color: #fff;
      padding: 0.51rem 1rem;
      width: auto;
      border: none;
      margin: 0 10px;
      white-space: normal;
      outline: none;
      font-weight: 600;
      cursor:pointer;
     }
    .permission__action-btn.action-allow {
      background: #444;
      &:hover {
        box-shadow: inset 0 0 0 1px #2ebaae;
        }
    }
    .permission__action-btn.action-close {
      background: #aaa;
    }
  }

  ${below.tiny`
    width: 90%;
    left: 5%;
    .permission__content {
      .permission__details {
        .permission__main-contain {
          font-size: 0.75rem;
          font-weight: 500;
        }
        .permission__info-content {
          font-size: 0.75rem;
        }
      }
    }
    .permission__action {
      .permission__action-btn {
        padding: 0.25rem 0.5rem
      }
    }
  `}
`