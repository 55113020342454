export const isWindow = typeof window !== 'undefined'

export const EXP_MODE = {
  DEFAULT: 'DEFAULT',
  ALPHA: 'ALPHA',
  BETA: 'BETA'
}

function stringToNumArray (str, defaultValue) {
  if (!(str && str.trim())) {
    return defaultValue
  }
  return str
    .trim()
    .split(/-|,|~|_/)
    .map(Number)
}

function getVariantWeight (variant, defaultWeight) {
  return (
    variant && variant.live && stringToNumArray(variant.weight, defaultWeight)
  )
}

function isInRange (start, end, val) {
  return val >= start && val <= end
}

export function getVariant (alpha, beta) {
  if (isWindow) {
    let userSegment = window.userSegment || 100
    let alphaWeights = getVariantWeight(alpha, [1, 20])
    let betaWeights = getVariantWeight(beta, [21, 40])

    if (
      alphaWeights &&
      isInRange(alphaWeights[0], alphaWeights[1], userSegment)
    ) {
      return EXP_MODE.ALPHA
    } else if (
      betaWeights &&
      isInRange(betaWeights[0], betaWeights[1], userSegment)
    ) {
      return EXP_MODE.BETA
    }
  }
  return EXP_MODE.DEFAULT
}
