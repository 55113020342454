import React from 'react'
import { PopupContainer } from '../styles'

export class PermissionPopup extends React.Component {

  handleAction = (isAllowed, event) => {
    event.preventDefault()
    event.stopPropagation()
    let notificationEvent = new CustomEvent('soft.notification', {
      detail: {
        isAllowed: isAllowed
      }
    })
    let popupContainer = document.getElementById('permission--container')
    document.dispatchEvent(notificationEvent)
    popupContainer.style.display = 'none'
  }

  render () {
    return (
      <PopupContainer id='permission--container' data-testid='permission--container'>
        <div className='permission__content'>
          <div className='permission__brand-img'>
            <img src='/img/Logo.png' alt="Logo"/>
          </div>
          <div className='permission__details'>
            <p className='permission__main-contain'>
              Path Money would like to send you news and updates.
            </p>
            <p className='permission__info-content'>
              Notifications can be turned off anytime from browser settings.
            </p>
          </div>
        </div>
        <div className='permission__action'>
          <button
            className='permission__action-btn action-close'
            onClick={this.handleAction.bind(this, false)}
            data-testid="action-close"
          >
            Close
          </button>
          <button
            className='permission__action-btn action-allow'
            onClick={this.handleAction.bind(this, true)}
            data-testid="action-allow"
          >
            Allow
          </button>
        </div>
      </PopupContainer>
    )
  }
}
