import qs from 'qs'

const injectAimtellTrackingCode = () => {
  const params = qs.parse(window.location.search.slice(1))
  if (params['no-push']) return
  // Inject aimtell tracking code before the end of the body tag
  const body = document.querySelector('body')
  const script = document.createElement('script')
  script.type = 'text/javascript'
  const scriptId = 'aimtell-tracking-code'
  script.id = scriptId
  script.innerHTML = `
    var _at = {};
    window._at.track =
      window._at.track ||
      function() {
        (window._at.track.q = window._at.track.q || []).push(arguments);
      };
    _at.domain = "path.money/";
    _at.owner = "8ce1dfd2a545";
    _at.idSite = "25481";
    _at.attributes = {};
    _at.webpushid = "web.2.aimtell.com";
    (function() {
      var u = "//s3.amazonaws.com/cdn.aimtell.com/trackpush/";
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.async = true;
      g.defer = true;
      g.src = u + "trackpush.min.js";
      s.parentNode.insertBefore(g, s);
    })();
  `
  script.async = false
  script.defer = true
  const oldScript = document.getElementById(scriptId)
  oldScript && oldScript.remove()
  body.appendChild(script)
}

export default injectAimtellTrackingCode