import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { FaBars } from 'react-icons/fa'

import { NavWrapper, NavTitle } from '../styles'
import styled from '@emotion/styled'
import { below } from '../utils/media-query'

const ToggleMenu = css`
  position: absolute;
  right: 0;
  padding-right: 74px;
  ${below.med`
      padding-right: 0px;
  `}
  a {
    color: #3c3b3b;
    font-size: 1.1rem;
    svg {
      transition: color 0.2s ease, border-bottom-color 0.2s ease;
    }
    ${below.med`
      margin-right: 1.5rem;
    `}
  }
`

const Logo = styled.img`
  height: 24px;
  margin: 0 auto;
`;

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0 3rem;
  ${below.med`
    padding: 0;
  `}
`;

const Navbar = props => {
  return (
    <NavWrapper role="navigation" aria-label="main-navigation">
          <Link to="/" title="Logo">
            <Logo src="/img/3.svg" />
          </Link>

        <div css={ToggleMenu}>
          {/* eslint-disable-next-line  */}
          <a data-testid="toggle-icon" onClick={() => props.toggleMenu()}>
            <FaBars />
          </a>
        </div>
    </NavWrapper>
  )
}

export default Navbar
